<template>
    <div ref="org"></div>
</template>

<script>
  import { OrgChart } from "d3-org-chart";
  // import * as d3 from 'd3'

  export default {
    name: "Chart",

    props: ["data"],

    data() {
      return {
        chartReference: null
      };
    },

    watch: {
      data(value) {
        this.renderChart(value);
      }
    },

    methods: {

      renderChart(data) {
        let chart = this.$refs.org
        if (!this.chartReference) {
          this.chartReference = new OrgChart();
        }
        this.chartReference
          .container(chart) // node or css selector
          .data(data)
          .nodeHeight(function(d){
            console.log(d)
            return 150
          })
          .nodeWidth(function(d){
            console.log(d)
            return 210
          })
          .childrenMargin(function(d){
            console.log(d)
            return 40
          })
          .buttonContent(({ node }) => {
            return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9">
            ${node.data._directSubordinates}
            </div>`;
          })
          .nodeContent(function(d) {
            return `
                 <div style="padding-top:30px;background-color:none;margin-left:1px;height:${d.height}px;border-radius:2px;">
                    <div style="height:${d.height - 32}px;padding-top:0px;background-color:white;border:1px solid lightgray;">
                        <div style="margin-top:-30px;background-color:${d.data.situationColor};height:10px;width:${d.width - 2}px;border-radius:1px"></div>
                       <div style="background-color:#FFFFFF;margin-top:-30px;margin-left:${d.width / 2 - 30}px;border-radius:100px;width:60px;height:60px;
                       border:1px solid lightgray;padding: 18px">
                            ${d.data.level.name}
                        </div>
                       <div style="padding:10px; padding-top:20px;text-align:center">
                           <div style="color:#111672;font-size:16px;font-weight:bold">
                                ${d.data.full_name}
                            </div>
                           <div style="color:#404040;font-size:10px">
                                ${d.data.tel}
                           </div>
                       </div>
                       <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
                         <div > ${d.data.personalPoint} <br/> Pts</div>
                         <div > ${d.data.code} <br/> ${d.data.createdAt}</div>
                       </div>
                     </div>
              </div>
  `;
          })
          .compact(false)
          .render()
          .fit();
      }
    },

    mounted() {
      this.renderChart(this.data);
    }
  };
</script>

<style scoped>

</style>

