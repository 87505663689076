import { render, staticRenderFns } from "./legend.vue?vue&type=template&id=17d943da&scoped=true"
import script from "./legend.vue?vue&type=script&lang=js"
export * from "./legend.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d943da",
  null
  
)

export default component.exports