<template>
  <section class="cart-section section-b-space" style="position:relative;">
    <!--<networking :network="customerNet" :customer="customer"/>-->
    <LegendComponent :legend="legendCustomer"/>
    <chart :data="customer"/>
  </section>
</template>

<script>

// import networking from "./networking";
import Chart from "./../../../../components/chart/Chart";
import LegendComponent from "./../legend"
// import _ from 'lodash'
import axios from "axios";
import {mapGetters} from "vuex";


// import * as d3 from 'd3';
// const flatten = function(into, node) {
//   if (node == null) return into;
//   if (Array.isArray(node)) return node.reduce(flatten, into);
//   into.push(node);
//   return flatten(into, node.children);
// };

export default {
  name: "networkingWrapper",

  components: {
    // networking,
    LegendComponent,
    Chart
  },


  data() {
    return {
      customer: ''
    };
  },

  methods: {

    async fetchCustomerNetwork(payload) {
      let loader = this.$loading.show();
      let pdts = await axios.get(`customers/${payload.customer}/${payload.dashtab}`);
      // const customer = pdts.data.data;
      this.customer = pdts.data.data
      // _.concat(
      // [{
      //   name: customer.full_name,
      //   id: customer.id,
      //   situationColor: customer.situationColor,
      //   situationLabel: customer.situationLabel,
      //   level: customer.level.name,
      //   code: customer.code,
      //   point: customer.points,
      //   tel: customer.tel,
      //   createdAt: customer.created_at
      // }],
      // _.map(flatten([], customer.children), (o) => ({
      //   name: o.full_name,
      //   id: o.id,
      //   parentId: o.parent_id,
      //   situationColor: o.situationColor,
      //   situationLabel: o.situationLabel,
      //   level: o.level.name,
      //   code: o.code,
      //   point: o.points,
      //   tel: o.tel,
      //   createdAt: o.created_at
      // })));
      loader.hide();
    },
  },

  watch: {
    user: {
      immediate: true,
      handler: function (val) {
        this.fetchCustomerNetwork({customer: val.id, dashtab: 'network'});
      }
    }
  },


  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("setting", [
      "legendCustomer"
    ])
  },

  mounted() {
    this.fetchCustomerNetwork({customer: this.user.id, dashtab: 'network'});
  }
};
</script>

<style scoped>

</style>
