<template>
    <div class="border rounded p-3" style="max-width: 250px; position: absolute; right: 50px; top:8px;">
        <table>
          <tr v-for="(l, i) in legend" :key="i">
            <td>
              <span><i class="fa fa-circle" :style="{color: l.code}"></i></span>
            </td>
            <td>{{ l.label }}</td>
          </tr>
        </table>
    </div>
</template>

<script>
export default {
  name: "LegendComponent",

  props: {
    legend: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style scoped>

</style>